import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { Pension, PensionIrrelevantReasons, PensionMeetingTypes } from '../../../interfaces/Pension';
import Modules from '../../../utils/modules';
import { TaxReturn } from '../../../interfaces/taxReturn';
import { formatDate } from '../../../utils/formatDate';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [pension, setPension] = useState<Pension>(profileContextData.currentRound as Pension);

    useEffect(() => {
        setPension(profileContextData.currentRound as Pension);
    }, [profileContextData.currentRound]);

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(
        users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('pension')),
    );
    const [repOperators, setRepOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));
    const [showDateModal, setShowDateModal] = useState(false);
    const [mislakaSubmissionDate, setMislakaSubmissionDate] = useState<Date>(new Date());

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('pension')));
        setRepOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);

    const hasOperator = (object: any): object is Pension | TaxReturn => {
        return 'operator' in object;
    };

    const handleDate = async () => {
        if (!pension) return;

        await onEditRound({ status: 'invalidMislaka', mislakaSubmissionDate }, selectedModuleName, pension._id, () =>
            setPension({
                ...pension,
                status: 'invalidMislaka',
                mislakaSubmissionDate,
            }),
        );
        setShowDateModal(false);
    };

    const onInvestmentRoundChecked = async (checked: boolean) => {
        if (!pension) return;

        await onEditRound({ isInvestmentRound: checked }, selectedModuleName, pension._id, () =>
            setPension({ ...pension, isInvestmentRound: checked }),
        );
    };

    const onSendFormsAutomaticallyChecked = async (checked: boolean) => {
        if (!pension) return;

        await onEditRound({ sendFormsAutomatically: checked }, selectedModuleName, pension._id, () =>
            setPension({ ...pension, sendFormsAutomatically: checked }),
        );
    };
    const onStatusSelected = async (status: string) => {
        if (!pension) return;

        if (status === 'invalidMislaka') {
            setShowDateModal(true);
            return;
        }

        await onEditRound({ status }, selectedModuleName, pension._id, () => setPension({ ...pension, status }));
    };

    const isValidPOA = () => {
        return true;
    };

    const onAgentChanged = (userId: string) => {
        const prevRep = pension[repType];
        setPension({ ...pension, [repType]: userId });
        onEditRound({ [repType]: userId }, selectedModuleName, pension._id, undefined, () => setPension({ ...pension, [repType]: prevRep }));
    };

    const lastChangeDateString = pension?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(pension.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div>
                {client && pension ? (
                    <Row>
                        <Col>
                            <div className="select-area">
                                {allowedStatusesOptions && allowedStatusesOptions.length > 0 ? (
                                    <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                                        <Select
                                            options={allowedStatusesOptions.map((option: any, index: number) => ({
                                                label: camelCaseToText(option),
                                                value: option,
                                            }))}
                                            onChange={(item: any) => onStatusSelected(item.value)}
                                            value={{ label: camelCaseToText(pension.status), value: pension.status }}
                                            className="d-flex flex-wrap"
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                    // minWidth: '300px',
                                                }),
                                                control: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                }),
                                            }}
                                        />
                                    </InputWrapper>
                                ) : null}

                                {pension.status === 'temporarilyIrrelevant' && (
                                    <div className="inputWrapper input-field select-field">
                                        {camelCaseToText(pension.temporarilyIrrelevantReason)} until:{' '}
                                        {formatDate(pension.temporarilyIrrelevantEndDate, true)}
                                    </div>
                                )}

                                {pension.status === 'irrelevant' && (
                                    <InputWrapper status={{ text: '' }} label={'Irrelevant Reason'} wrapperClass="input-field select-field">
                                        <select
                                            value={(pension as Pension).irrelevantReason}
                                            className="form-select"
                                            name="irrelevantReason"
                                            onChange={(e: any) => {
                                                onEditRound({ irrelevantReason: e.target.value }, selectedModuleName, pension._id, () =>
                                                    setPension({
                                                        ...pension,
                                                        irrelevantReason: e.target.value,
                                                    }),
                                                );
                                            }}
                                        >
                                            <option value="" selected={true}>
                                                Please choose
                                            </option>
                                            {Object.values(PensionIrrelevantReasons).map((reason: string, index: number) => (
                                                <option key={index} value={reason}>
                                                    {camelCaseToText(reason)}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                )}

                                {repAgents && repAgents.length > 0 ? (
                                    <InputWrapper status={{ text: '' }} label={'Agent'} wrapperClass="input-field select-field">
                                        <Select
                                            options={repAgents.map((option: any, index: number) => ({
                                                label: option.fullName,
                                                value: option._id,
                                            }))}
                                            onChange={(item: any) => onAgentChanged(item.value)}
                                            value={{
                                                label: repAgents.find((rep: any) => rep._id === (pension as Pension).agent)?.fullName,
                                                value: (pension as Pension).agent,
                                            }}
                                            className="d-flex flex-wrap"
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                    // minWidth: '300px',
                                                }),
                                                control: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                }),
                                            }}
                                        />
                                    </InputWrapper>
                                ) : (
                                    <div className="pension-status">No {repType} found.</div>
                                )}

                                {repOperators && repOperators.length > 0 && hasOperator(pension) ? (
                                    <InputWrapper status={{ text: '' }} label={'Operator'} wrapperClass="input-field select-field">
                                        <select
                                            value={pension.operator}
                                            className="form-select"
                                            name="operator"
                                            onChange={(e: any) => {
                                                onEditRound({ operator: e.target.value }, selectedModuleName, pension._id, () =>
                                                    setPension({
                                                        ...pension,
                                                        operator: e.target.value,
                                                    }),
                                                );
                                            }}
                                        >
                                            <option disabled value="">
                                                Select Operator
                                            </option>

                                            {repOperators.map((operator: User, index: number) => (
                                                <option key={index} value={operator._id}>
                                                    {operator.fullName}
                                                </option>
                                            ))}
                                        </select>
                                    </InputWrapper>
                                ) : (
                                    <div className="pension-status">No operators found.</div>
                                )}

                                <InputWrapper status={{ text: '' }} label={'Signing probability'} wrapperClass="input-field select-field">
                                    <select
                                        value={pension.signingProbability}
                                        className="form-select"
                                        name="signingProbability"
                                        onChange={(e: any) => {
                                            onEditRound({ signingProbability: e.target.value }, selectedModuleName, pension._id, () =>
                                                setPension({
                                                    ...pension,
                                                    signingProbability: e.target.value,
                                                }),
                                            );
                                        }}
                                    >
                                        <option value="" selected={true}>
                                            Please choose
                                        </option>
                                        <option value="high">High</option>
                                        <option value="medium">Medium</option>
                                        <option value="low">Low</option>
                                    </select>
                                </InputWrapper>

                                <InputWrapper>
                                    <br />
                                    <Form.Check
                                        type="checkbox"
                                        label="Send forms to insurance company automatically"
                                        checked={pension.sendFormsAutomatically}
                                        onChange={(e) => onSendFormsAutomaticallyChecked(e.target.checked)}
                                    />
                                </InputWrapper>

                                <div>
                                    <b>Mislaka submission date: </b>
                                    <span>{formatDate((pension as Pension).mislaka?.submissionDate, true)}</span>
                                </div>
                                {!isValidPOA() ? (
                                    <Alert variant="danger" className="my-2">
                                        POA not valid on submission date
                                    </Alert>
                                ) : null}
                            </div>
                        </Col>
                        <Col>
                            <InputWrapper status={{ text: '' }} label={''} wrapperClass="input-field select-field">
                                <Form.Check
                                    type="checkbox"
                                    label="Investment round"
                                    checked={pension.isInvestmentRound}
                                    onChange={(e) => onInvestmentRoundChecked(e.target.checked)}
                                />
                            </InputWrapper>
                            <br />

                            <div className="inputWrapper input-field select-field">
                                <label className="form-label">
                                    Agent meeting
                                    {pension.meetingType && pension.agentMeetingTime ? (
                                        <>
                                            <span>
                                                {' '}
                                                by <b>{camelCaseToText(pension.meetingType)}</b>
                                            </span>
                                            <span> at {formatDate(pension.agentMeetingTime)}</span>
                                        </>
                                    ) : (
                                        <span> not scheduled yet</span>
                                    )}
                                </label>
                                <br />
                                <label className="form-label">
                                    {!!pension.agentMeetingTime && pension.meetingType !== PensionMeetingTypes.faceToFace && (
                                        <a href={pension.zoomUri || `tel:${client.phoneNumber}`} target="_blank">
                                            Click to connect
                                        </a>
                                    )}
                                </label>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <div>Sorry, no data yet.</div>
                )}
            </div>

            <Modal className="date-modal" backdropClassName="date-backdrop" show={showDateModal} onHide={() => setShowDateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{'Select Mislaka Submission Date'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker
                        name="mislakaSubmissionDate"
                        className={'date-input'}
                        selected={mislakaSubmissionDate}
                        onChange={(date: Date) => setMislakaSubmissionDate(date)}
                        dateFormat={'dd/MM/yyyy'}
                        placeholderText={'select date'}
                        customInput={<input className="btn btn-light date-input" />}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDateModal(false)}>
                        {'Cancel'}
                    </Button>
                    <Button variant="success" onClick={handleDate}>
                        {'Done'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Details;

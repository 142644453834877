import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormInput } from '../../components';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { Client, ClientTypeOptions, EmploymentStatuses, GenderOptions, MaritalStatusOptions, TargetAudienceStatuses } from '../../interfaces/Client';
import { PricingPlans } from '../../interfaces/Employer';
import { RatingLevel } from '../../interfaces/Client';
import { useTranslation } from 'react-i18next';
import { APICore } from '../../utils/api/apiCore';
import { deleteClient } from '../../utils/api/clients';
import { CampaignTypes, SourceTypes } from '../business-dashboard/consts';
import globalErrorHandler from '../../utils/globalErrorHandler';
interface AddCustomerProps {
    show: boolean;
    onHide: () => void;
    onAddClient: (newClient: Client, oldClient?: Client) => void;
    client: Client;
}

const EditClient = ({ show, onHide, onAddClient, client }: AddCustomerProps) => {
    const loggedInUser = new APICore().getLoggedInUser().user;
    const { t } = useTranslation('clients');
    const [currentClient, setCurrentClient] = useState<Client>(client);

    useEffect(() => {
        setCurrentClient(client);
    }, [client]);

    const onInputChange = (e: any) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;

        if (name === 'isVIP' && !value) {
            setCurrentClient({
                ...currentClient,
                isVIP: false,
                vipDetails: '',
            });
            return;
        }

        if (name === 'notInterested' && !value) {
            setCurrentClient({
                ...currentClient,
                notInterested: false,
            });
            return;
        }

        if (name === 'isIrrelevant' && !value) {
            setCurrentClient({
                ...currentClient,
                isIrrelevant: false,
                irrelevantReason: '',
            });
            return;
        }

        if (name === 'sourceType') {
            setCurrentClient({
                ...currentClient,
                source: {
                    ...currentClient.source,
                    sourceType: value,
                },
            });
            return;
        }

        if (name === 'campaign') {
            setCurrentClient({
                ...currentClient,
                source: {
                    ...currentClient.source,
                    campaign: value,
                },
            });
            return;
        }

        if (name === 'campaignName') {
            setCurrentClient({
                ...currentClient,
                source: {
                    ...currentClient.source,
                    campaignName: value,
                },
            });
            return;
        }

        setCurrentClient({
            ...currentClient,
            [name]: value,
        });
    };

    const onSubmit = (newClient: Client) => {
        onAddClient(newClient, client);
    };

    const jotFormRatingOptions = Object.keys(RatingLevel)
        .filter((key) => !isNaN(Number(RatingLevel[key as keyof typeof RatingLevel])))
        .map((key: string, index: number) => ({
            label: t(key) || '',
            value: index,
        }));

    const isUserAllowedToDelete = loggedInUser.isAdmin || loggedInUser.roles.includes('support');

    const handleDeleteClick = async () => {
        if (!isUserAllowedToDelete) return;

        if (!client) return;

        try {
            const userAnswerToIsraeliId = prompt(
                `Are you sure you want to delete ${client.firstName} ${client.lastName}?\nPlease enter the client's Israeli ID number to confirm`,
            );
            if (!userAnswerToIsraeliId && client.idNumber) return;
            debugger;

            if (userAnswerToIsraeliId && userAnswerToIsraeliId !== client.idNumber) {
                alert('Wrong Israeli ID number');
                return;
            }

            await deleteClient(client._id, userAnswerToIsraeliId || '');
            alert('Client deleted successfully');
            onHide();
            window.location.href = '/';
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">{client ? 'Edit Client' : 'Add New Client'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <FormInput
                        // value={client?.firstName}
                        label="First Name"
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.firstName}
                    />
                    <FormInput
                        label="Last Name"
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.lastName}
                    />

                    <FormInput
                        label="Email Address"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.email}
                    />
                    <FormInput
                        label="Phone"
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.phoneNumber}
                    />
                    <FormInput
                        label="ID Number"
                        type="text"
                        name="idNumber"
                        maxLength={9}
                        placeholder="Enter israel ID number"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.idNumber}
                    />
                    <FormInput
                        label="ID Issue Date"
                        type="date"
                        name="idIssueDate"
                        placeholder="Enter ID issue date"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.idIssueDate ? currentClient.idIssueDate.toString().substring(0, 10) : ''}
                    />
                    <FormInput
                        label="Gender"
                        type="select"
                        name="gender"
                        placeholder="select gender"
                        containerClass={'mb-3'}
                        options={GenderOptions.map((type) => ({
                            label: camelCaseToText(type) || '',
                            value: type,
                        }))}
                        onChange={onInputChange}
                        value={currentClient.gender}
                    />
                    <FormInput
                        label="Marital Status"
                        type="select"
                        name="maritalStatus"
                        placeholder="select gender"
                        containerClass={'mb-3'}
                        options={MaritalStatusOptions.map((type) => ({
                            label: camelCaseToText(type) || '',
                            value: type,
                        }))}
                        onChange={onInputChange}
                        value={currentClient.maritalStatus}
                    />
                    <FormInput
                        label="Date Of Birth"
                        type="date"
                        name="dateOfBirth"
                        placeholder="Enter date of birth"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.dateOfBirth ? currentClient.dateOfBirth.toString().substring(0, 10) : ''}
                    />
                    {/*select forminput for editing employmentStatus with the options of  employmentStatus from the client.ts*/}

                    <FormInput
                        label="Source Type"
                        type="select"
                        name="sourceType"
                        placeholder="select source type"
                        containerClass={'mb-3'}
                        options={Object.values(SourceTypes).map((source) => ({
                            label: camelCaseToText(source) || '',
                            value: source,
                        }))}
                        onChange={onInputChange}
                        value={currentClient.source.sourceType}
                    />

                    {currentClient.source.sourceType === SourceTypes.campaign ? (
                        <FormInput
                            label="Campaign"
                            type="select"
                            name="campaign"
                            placeholder="select campaign"
                            containerClass={'mb-3'}
                            options={Object.values(CampaignTypes).map((campaign) => ({
                                label: camelCaseToText(campaign) || '',
                                value: campaign,
                            }))}
                            onChange={onInputChange}
                            value={currentClient.source.campaign || ''}
                        />
                    ) : null}

                    {currentClient.source.sourceType === SourceTypes.campaign ? (
                        <FormInput
                            label="Campaign Name"
                            type="text"
                            name="campaignName"
                            placeholder="Enter campaign name"
                            containerClass={'mb-3'}
                            onChange={onInputChange}
                            value={currentClient.source.campaignName}
                        />
                    ) : null}

                    <FormInput
                        label="Employment Status"
                        type="select"
                        name="employmentStatus"
                        placeholder="select employment status"
                        containerClass={'mb-3'}
                        options={EmploymentStatuses.map((status) => ({
                            label: camelCaseToText(status) || '',
                            value: status,
                        }))}
                        onChange={onInputChange}
                        value={currentClient.employmentStatus}
                    />

                    <FormInput
                        // value={currentClient.firstName}
                        label="Retirement Age"
                        type="number"
                        name="retirementAge"
                        placeholder="If needed, enter the retirement age"
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                        value={currentClient.retirementAge}
                    />
                    <FormInput
                        label="Client Type"
                        type="select"
                        name="clientType"
                        placeholder="select client type"
                        containerClass={'mb-3'}
                        options={ClientTypeOptions.map((type) => ({
                            label: camelCaseToText(type) || '',
                            value: type,
                        }))}
                        onChange={onInputChange}
                        value={currentClient.clientType}
                    />

                    {/* <FormInput
                        label={t("level of awarness to personal financial situation")}
                        type="select"
                        name="personalFinanceKnowledge"
                        placeholder={t("select the right level")}
                        containerClass={'mb-3'}
                        options={jotFormRatingOptions}
                    />

                    <FormInput
                        label={t("comfortability with financial decision making")}
                        type="select"
                        name="financialIndependenceLevel"
                        placeholder={t("select the right level")}
                        containerClass={'mb-3'}
                        options={jotFormRatingOptions}
                    /> */}

                    <FormInput
                        label="VIP"
                        type="checkbox"
                        name="isVIP"
                        checked={currentClient.isVIP}
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                    />

                    <FormInput
                        label="Not Interested"
                        type="checkbox"
                        name="notInterested"
                        checked={currentClient.notInterested}
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                    />

                    <FormInput
                        label="Unsubscribed from bot messaging"
                        type="checkbox"
                        name="unsubscribedFromBotMessaging"
                        checked={currentClient.unsubscribedFromBotMessaging}
                        containerClass={'mb-3'}
                        onChange={onInputChange}
                    />

                    {currentClient.isVIP && (
                        <FormInput
                            label="VIP Details"
                            type="text"
                            name="vipDetails"
                            placeholder="Enter VIP details"
                            containerClass={'mb-3'}
                            onChange={onInputChange}
                            value={currentClient.vipDetails}
                        />
                    )}

                    <FormInput
                        label={'Target Audience Status'}
                        type="select"
                        name="targetAudienceStatus"
                        placeholder={'Select target audience status'}
                        containerClass={'mb-3'}
                        options={Object.keys(TargetAudienceStatuses).map((status) => ({
                            label: camelCaseToText(status) || '',
                            value: status,
                        }))}
                        value={currentClient.targetAudienceStatus}
                        onChange={onInputChange}
                    />

                    {currentClient.targetAudienceStatus === TargetAudienceStatuses.irrelevantClient && (
                        <FormInput
                            label="Irrelevant Reason"
                            type="text"
                            name="irrelevantReason"
                            placeholder="Enter irrelevant reason"
                            containerClass={'mb-3'}
                            onChange={onInputChange}
                            value={currentClient.irrelevantReason}
                        />
                    )}

                    <div className="d-flex justify-content-between flex-row-reverse">
                        <div className="text-end">
                            <Button variant="success" type="submit" className="waves-effect waves-light me-1" onClick={() => onSubmit(currentClient)}>
                                Save
                            </Button>
                        </div>
                        <div className="text-end">
                            <Button
                                variant="danger"
                                className="waves-effect waves-light me-1"
                                disabled={!isUserAllowedToDelete}
                                onClick={handleDeleteClick}
                            >
                                Delete Client
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditClient;

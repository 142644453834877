import { APICore, API_URL } from './apiCore';
const api = new APICore();

const getSignatures = (params: { clientId: string; documentType?: string; insuranceId?: string }) => {
    const relativeUrl = '/signatures';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const archiveSignature = (signedDocumentId: string) => {
    const relativeUrl = `/signatures/${signedDocumentId}/archive`;
    return api.update(`${API_URL}${relativeUrl}`, {});
};

const getB2Form = (productId: string, preview?: boolean) => {
    const relativeUrl = `/signatures/b2Form`;
    if (preview) return api.getFile(`${API_URL}${relativeUrl}`, { productId });
    else return api.get(`${API_URL}${relativeUrl}`);
};

const getSigningUrl = (productTransferId: string, preview?: boolean) => {
    const relativeUrl = `/signatures/signing-url-single`;
    if (preview) return api.getFile(`${API_URL}${relativeUrl}`, { preview, productTransferId });
    // return api.get(`${PENSION_URL}${relativeUrl}`,{joinAndTransfer:true, preview:true});
    else return api.get(`${API_URL}${relativeUrl}`, { productTransferId });
};

const getMultipleDocsSigningUrl = ({
    preview,
    clientId,
    pensionId,
    investmentId,
}: {
    clientId: string;
    pensionId?: string;
    investmentId?: string;
    preview?: boolean;
}) => {
    const relativeUrl = `/signatures/signing-url-multiple`;
    if (preview)
        return api.getFile(`${API_URL}${relativeUrl}`, {
            preview,
            pensionId,
            investmentId,
            clientId,
        });
    // return api.get(`${PENSION_URL}${relativeUrl}`,{joinAndTransfer:true, preview:true});
    else return api.get(`${API_URL}${relativeUrl}`, { clientId, pensionId, investmentId });
};

export { getSignatures, archiveSignature, getSigningUrl, getMultipleDocsSigningUrl, getB2Form };

import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { ApiError } from './ApiError';
import { User } from '../../interfaces/User';
import { Client } from '../../interfaces/Client';
import { FILE } from 'dns';
import { te } from 'date-fns/locale';

const AUTH_SESSION_KEY = 'user';
const TEMP_USER = 'temp_user';

let API_URL: string;
let PENSION_URL: string;
let TAX_URL: string;
let MORTGAGE_URL: string;
let BANK_FEES_URL: string;
let LOAN_URL: string;
let DEPOSIT_URL: string;
let INSURANCE_URL: string;
let INVESTMENT_URL: string;
let PENSION_ZOON_URL: string;
let TASKS_URL: string;
let PUBLIC_API_URL: string;
let SOCKET_URL: string;
let DASHBOARD_URL: string;
let PENSION_ZOOM_WEBSITE_URL: string;
let FILE_DATA_EXTRACTION_URL: string;
let CLIENT_SEARCH_URL: string;
let CLIENT_EMAILS_URL: string;
let TERMINAL_URL: string;

if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
    API_URL = 'http://localhost:4000/api';
    PENSION_URL = 'http://localhost:4005/api';
    TAX_URL = 'http://localhost:4006/api';
    MORTGAGE_URL = 'http://localhost:4015/api';
    BANK_FEES_URL = 'http://localhost:4016/api';
    LOAN_URL = 'http://localhost:4013/api';
    DEPOSIT_URL = 'http://localhost:4011/api';
    INSURANCE_URL = 'http://localhost:4012/api';
    SOCKET_URL = 'http://localhost:4001';
    PUBLIC_API_URL = 'http://localhost:4000/api';
    TASKS_URL = 'http://localhost:4008/api';
    DASHBOARD_URL = 'http://localhost:4020/api';
    INVESTMENT_URL = 'http://localhost:4018/api';
    PENSION_ZOON_URL = 'http://localhost:4019/api';
    PENSION_ZOOM_WEBSITE_URL = 'http://localhost:3000';
    FILE_DATA_EXTRACTION_URL = 'http://localhost:4022/api';
    CLIENT_SEARCH_URL = 'http://localhost:4021/api';
    CLIENT_EMAILS_URL = 'http://localhost:4024/api';
    TERMINAL_URL = 'http://localhost:4023/api';
} else if (window.location.hostname.includes('staging')) {
    API_URL = 'https://staging-api.allbetter.co.il/api';
    PENSION_URL = 'https://staging-api.allbetter.co.il:4005/api';
    TAX_URL = 'https://staging-api.allbetter.co.il:4006/api';
    MORTGAGE_URL = 'https://staging-api.allbetter.co.il:4015/api';
    BANK_FEES_URL = 'https://staging-api.allbetter.co.il:4016/api';
    LOAN_URL = 'https://staging-api.allbetter.co.il:4013/api';
    DEPOSIT_URL = 'https://staging-api.allbetter.co.il:4011/api';
    INSURANCE_URL = 'https://staging-api.allbetter.co.il:4012/api';
    SOCKET_URL = 'https://staging-api.allbetter.co.il:4001';
    PUBLIC_API_URL = 'https://staging.p.allbetter.co.il/api';
    TASKS_URL = 'https://staging-api.allbetter.co.il:4008/api';
    DASHBOARD_URL = 'https://staging-api.allbetter.co.il:4020/api';
    INVESTMENT_URL = 'https://staging-api.allbetter.co.il:4018/api';
    PENSION_ZOON_URL = 'https://staging-api.allbetter.co.il:4019/api';
    PENSION_ZOOM_WEBSITE_URL = 'https://pension-zoom-staging.allbetter.co.il';
    FILE_DATA_EXTRACTION_URL = 'https://staging-api.allbetter.co.il:4022/api';
    CLIENT_SEARCH_URL = 'https://staging-api.allbetter.co.il:4021/api';
    CLIENT_EMAILS_URL = 'https://staging-api.allbetter.co.il:4024/api';
    TERMINAL_URL = 'https://staging-api.allbetter.co.il:4023/api';
} else {
    API_URL = 'https://core-api.allbetter.co.il/api';
    PENSION_URL = 'https://core-api.allbetter.co.il:4005/api';
    TAX_URL = 'https://core-api.allbetter.co.il:4006/api';
    MORTGAGE_URL = 'https://core-api.allbetter.co.il:4015/api';
    BANK_FEES_URL = 'https://core-api.allbetter.co.il:4016/api';
    LOAN_URL = 'https://core-api.allbetter.co.il:4013/api';
    DEPOSIT_URL = 'https://core-api.allbetter.co.il:4011/api';
    INSURANCE_URL = 'https://core-api.allbetter.co.il:4012/api';
    SOCKET_URL = 'https://core-api.allbetter.co.il:4001';
    PUBLIC_API_URL = 'https://p.allbetter.co.il/api';
    TASKS_URL = 'https://core-api.allbetter.co.il:4008/api';
    DASHBOARD_URL = 'https://core-api.allbetter.co.il:4020/api';
    INVESTMENT_URL = 'https://core-api.allbetter.co.il:4018/api';
    PENSION_ZOON_URL = 'https://core-api.allbetter.co.il:4019/api';
    PENSION_ZOOM_WEBSITE_URL = 'https://pension-zoom.allbetter.co.il';
    FILE_DATA_EXTRACTION_URL = 'https://core-api.allbetter.co.il:4022/api';
    CLIENT_SEARCH_URL = 'https://core-api.allbetter.co.il:4021/api';
    CLIENT_EMAILS_URL = 'https://core-api.allbetter.co.il:4024/api';
    TERMINAL_URL = 'https://core-api.allbetter.co.il:4023/api';
}

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {};

interface RequestOptions {
    method: string;
    headers: {
        'Content-Type'?: string;
        Authorization?: string;
    };
    body?: any;
}

const getUserFromCookie = (): { token: string; user: User } => {
    const str = localStorage.getItem(AUTH_SESSION_KEY);
    const userAndTokenObject = str ? (typeof str === 'object' ? str : JSON.parse(str)) : {};
    const user = userAndTokenObject.user;
    if (!user) return userAndTokenObject;
    const userHasRoles = Array.isArray(user.roles);
    const userHasRole = typeof user.role === 'string' && user.role.length > 0;
    if (!userHasRoles && userHasRole) user.roles = [user.role];
    else if (!userHasRoles) user.roles = [];
    return userAndTokenObject;
};

interface UserData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

class APICore {
    /**
     * Fetches data from given url
     */

    getRequestOptions = (method: string, body?: any): RequestOptions => {
        const user = getUserFromCookie();
        const requestOptions: RequestOptions = {
            method,
            headers: {},
        };

        if (user && user.token) requestOptions.headers['Authorization'] = 'Bearer ' + user.token;

        if (body) {
            if (body instanceof FormData) requestOptions.body = body;
            else {
                requestOptions.body = JSON.stringify(body);
                requestOptions.headers['Content-Type'] = 'application/json';
            }
        }

        return requestOptions;
    };

    get = async (url: string, params?: any, token?: string) => {
        let queryString = '';
        if (typeof params === 'string') {
            queryString = params;
        } else {
            queryString = params
                ? Object.keys(params)
                      .filter((key) => params[key] !== undefined && params[key] !== null && params[key] !== '')
                      .map((key) => `${key}=${params[key] !== undefined ? params[key] : ''}`)
                      .join('&')
                : '';
        }

        const querySeparator = queryString ? '?' : ''; // to avoid cases where there is a question mark in the end of the url

        const options = this.getRequestOptions('GET');
        const result = await fetch(`${url}${querySeparator}${queryString}`, options);

        if (result.status === 401) {
            if (window.location.pathname !== '/auth/logout') window.location.href = '/auth/logout';
        }

        const json = await result.json();

        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }

        return json;
    };

    getWithBody = async (url: string, params?: any) => {
        const requestOptions: RequestOptions = this.getRequestOptions('GET', params);
        const result = await fetch(url, requestOptions);
        const json = await result.json();

        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }

        return json;
    };

    getFile = async (url: string, params?: any) => {
        let queryString = '';
        if (typeof params === 'string') {
            queryString = params;
        } else {
            queryString = params
                ? Object.keys(params)
                      .filter((key) => params[key] !== undefined && params[key] !== null && params[key] !== '')
                      .map((key) => `${key}=${params[key] !== undefined ? params[key] : ''}`)
                      .join('&')
                : '';
        }

        const querySeparator = queryString ? '?' : ''; // to avoid cases where there is a question mark in the end of the url

        const result = await fetch(`${url}${querySeparator}${queryString}`, this.getRequestOptions('GET'));

        if (!result.ok) {
            throw new ApiError({ status: result.status });
        }
        return result;
    };

    getFileWithBody = async (url: string, params?: any) => {
        const requestOptions: RequestOptions = this.getRequestOptions('POST', params);
        const result = await fetch(url, requestOptions);
        // const json = await result.json();

        if (!result.ok) {
            throw new ApiError({ status: result.status });
        }

        return result;
    };

    getMultiple = (urls: [string], params: any) => {
        return Promise.all(urls.map((url) => this.get(url, params)));
    };

    /**
     * post given data to url
     */
    create = async (url: string, data: any) => {
        // return axios.post(url, data);

        const result = await fetch(url, this.getRequestOptions('POST', data));
        const json: any = await result.json();

        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }
        return json;
    };

    /**
     * Updates patch data
     */
    updatePatch = async (url: string, data: any) => {
        const result = await fetch(url, this.getRequestOptions('PATCH', data));
        const json: any = await result.json();
        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }
        return result.json();
    };

    /**
     * Updates data
     */
    update = async (url: string, data: any) => {
        const result = await fetch(url, this.getRequestOptions('PUT', data));
        const json: any = await result.json();
        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }

        return json;
    };

    /**
     * Deletes data
     */
    delete = async (url: string) => {
        const result = await fetch(url, this.getRequestOptions('DELETE'));
        const json: any = await result.json();
        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }
        return json;
    };

    /**
     * post given data to url with file
     */
    createWithFile = async (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const result = await fetch(url, this.getRequestOptions('POST', formData));

        if (!result.ok) {
            throw new ApiError({ ...result, status: result.status });
        }

        return result.json();
    };

    /**
     * post given data to url with file
     */
    updateWithFile = async (url: string, data: any) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const result = await fetch(url, this.getRequestOptions('PUT', formData));
        const json: any = await result.json();

        if (!result.ok) {
            throw new ApiError({ ...json, status: result.status });
        }

        return json;
    };

    isUserAuthenticated = () => {
        const { user, token } = this.getLoggedInUser();

        if (!user) {
            return false;
        }
        const decoded: any = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session: any) => {
        if (session) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    setTempUser = (userData: UserData) => {
        localStorage.setItem(TEMP_USER, JSON.stringify(userData));
    };

    getTempUser = () => {
        const json = localStorage.getItem(TEMP_USER);
        if (json) return JSON.parse(json);
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = (): { token: string; user: User } => {
        return getUserFromCookie();
    };

    canHandleAllData = () => {
        return (
            this.getLoggedInUser().user.isAdmin ||
            this.getLoggedInUser().user.roles.includes('operator') ||
            this.getLoggedInUser().user.roles.includes('agent')
        );
    };

    canHandleSensitiveData = (user?: Client) => {
        //later change this back
        // const roles = [,'agent'];
        const roles = ['agent', 'interviewer', 'operator', 'analyst'];
        const userRoles = this.getLoggedInUser().user.roles;
        if (userRoles.some((role: string) => roles.includes(role)) || this.getLoggedInUser().user.isAdmin) return true;

        if (this.getLoggedInUser().user.roles.includes('interviewer')) {
            if (!user) return true;
            else if (user.pensionStatus === 'preZoom' || user.pensionStatus === 'postZoom' || user.pensionStatus === 'mislakaReceived') {
                return true;
            }
        }
        return false;
    };

    logOutUser = () => {
        localStorage.removeItem(AUTH_SESSION_KEY);
    };

    setUserInSession = (modifiedUser: any) => {
        const userInfo = localStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
const user = getUserFromCookie();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export {
    APICore,
    getUserFromCookie,
    setAuthorization,
    API_URL,
    PENSION_URL,
    TAX_URL,
    MORTGAGE_URL,
    BANK_FEES_URL,
    LOAN_URL,
    DEPOSIT_URL,
    INSURANCE_URL,
    SOCKET_URL,
    PUBLIC_API_URL,
    TASKS_URL,
    DASHBOARD_URL,
    INVESTMENT_URL,
    PENSION_ZOON_URL,
    PENSION_ZOOM_WEBSITE_URL,
    FILE_DATA_EXTRACTION_URL,
    CLIENT_SEARCH_URL,
    CLIENT_EMAILS_URL,
    TERMINAL_URL,
};

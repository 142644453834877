import React, { createContext, useState, useEffect } from 'react';
import { getUnansweredClients } from '../utils/api/messages';
import { ClientBySearch } from '../interfaces/ClientBySearch';
import globalErrorHandler from '../utils/globalErrorHandler';

interface UnansweredClientsContextProps {
    unansweredClients: ClientBySearch[];
    fetchUnansweredClients: () => Promise<void>;
    showUnansweredClientsWarningBanner: boolean;
}

export const UnansweredClientsContext = createContext<UnansweredClientsContextProps>({
    unansweredClients: [],
    fetchUnansweredClients: async () => {},
    showUnansweredClientsWarningBanner: false,
});

export const UnansweredClientsProvider = ({ children }: { children: React.ReactNode }) => {
    const [unansweredClients, setUnansweredClients] = useState<ClientBySearch[]>([]);
    const [showWarning, setShowWarning] = useState<boolean>(true);
    const showUnansweredClientsWarningBanner = showWarning && unansweredClients.length > 0;

    const fetchUnansweredClients = async () => {
        try {
            const response = await getUnansweredClients();
            setUnansweredClients(response.clients);
            setShowWarning(true);
        } catch (error: any) {
            if (error.status === 401) {
                console.error('Invalid Token', error);
            } else {
                globalErrorHandler(error);
            }
        }
    };

    useEffect(() => {
        // fetchUnansweredClients();
    }, []);

    return (
        <UnansweredClientsContext.Provider
            value={{
                unansweredClients,
                fetchUnansweredClients,
                showUnansweredClientsWarningBanner,
            }}
        >
            {children}
        </UnansweredClientsContext.Provider>
    );
};

export const useUnansweredClientsContext = () => {
    const context = React.useContext(UnansweredClientsContext);
    if (context === undefined) {
        throw new Error('useUnansweredClientsContext must be used within a UnansweredClientsProvider');
    }
    return context;
};

import { ProductTransfer } from './ProductTransfer';
import { User } from './User';
import { PensionProduct } from './PensionProduct';
import { File } from './File';
import { Client } from './Client';
import { ClientFile } from './ClientFile';

export enum SignatureStatus {
    Initial = 'initial',
    ClientSigned = 'clientSigned',
    RepSigned = 'repSigned',
}

export interface SignedDocument {
    _id: string;
    createdAt: string;
    client: Client;
    rep: User;
    file: ClientFile;
    files: ClientFile[];
    signedFile: string;
    requestSignId: string;
    clientSigningLink: string;
    agentSigningLink: string;
    completedFormLink: string;
    documentType: string;
    signatureStatus: SignatureStatus;
    ref: {
        productTransfer: ProductTransfer;
        productTransfers: ProductTransfer[];
        product: PensionProduct;
    };
}

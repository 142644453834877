import { APICore, PENSION_URL } from './apiCore';
import { FileType } from '../../interfaces/FileType';
import { bool } from 'yup';

const api = new APICore();

interface GetProductTransfersParams {
    page?: number;
    search?: string;
    status?: string;
    clientId?: string;
    companyId?: string;
    productType?: string;
    isActive?: string;
    action?: string;
    fromSubmissionDate?: string;
    toSubmissionDate?: string;
    fromCloseDate?: string;
    fromAgentSignatureDate?: string;
    toAgentSignatureDate?: string;
    fromLastChangeDate?: string;
    toLastChangeDate?: string;
    toCloseDate?: string;
    employmentStatus?: string;
}
const getProductTransfers = (params: GetProductTransfersParams) => {
    const relativeUrl = '/product-transfers/operation';
    return api.get(`${PENSION_URL}${relativeUrl}`, params);
};

const exportProductTransfers = (params: GetProductTransfersParams) => {
    const relativeUrl = '/product-transfers/operation/export';
    return api.getFile(`${PENSION_URL}${relativeUrl}`, params);
};

const uploadProductTransfers = (params: { file: Blob }) => {
    const relativeUrl = '/product-transfers/operation/import';
    return api.createWithFile(`${PENSION_URL}${relativeUrl}`, params);
};

const uploadFileToProductTransfer = (id: string, params: { file: File }) => {
    const relativeUrl = '/product-transfers/' + id + '/upload-file';
    return api.createWithFile(`${PENSION_URL}${relativeUrl}`, params);
};

const editProductTransfer = (id: string, params: any) => {
    const relativeUrl = '/product-transfers/' + id;

    return api.update(`${PENSION_URL}${relativeUrl}`, params);
};

const updateProductTransfer = (id: string, params: any) => {
    const relativeUrl = `/product-transfers/${id}/operation`;

    return api.update(`${PENSION_URL}${relativeUrl}`, params);
};

const resubmitB2 = (productId: string) => {
    const relativeUrl = `/products/${productId}/submitB2Form`;

    return api.create(`${PENSION_URL}${relativeUrl}`, {});
};

const archiveSigningDocument = (id: string) => {
    const relativeUrl = `/product-transfers/${id}/archive-signed-document`;

    return api.update(`${PENSION_URL}${relativeUrl}`, {});
};

const getEditProductTransferData = (id: string) => {
    const relativeUrl = `/product-transfers/${id}/edit`;
    return api.get(`${PENSION_URL}${relativeUrl}`);
};

const getProductTransferCompanies = () => {
    const relativeUrl = `/product-transfers/companies`;
    return api.get(`${PENSION_URL}${relativeUrl}`);
};

const recalculateSavings = (params: any) => {
    const relativeUrl = `/product-transfers/recalculate-all`;
    return api.create(`${PENSION_URL}${relativeUrl}`, params);
};

const mailProductTransfers = (productTransferIds: string[]) => {
    const relativeUrl = `/product-transfers/send-signed-documents-to-company`;
    return api.create(`${PENSION_URL}${relativeUrl}`, { productTransferIds });
};

const uploadInsuranceCompanyFile = (params: { file: Blob; companyName: String }) => {
    const relativeUrl = '/product-transfers/operations/insurance-company-upload-file';
    return api.createWithFile(`${PENSION_URL}${relativeUrl}`, params);
};

export {
    uploadInsuranceCompanyFile,
    getProductTransferCompanies,
    archiveSigningDocument,
    getEditProductTransferData,
    getProductTransfers,
    recalculateSavings,
    editProductTransfer,
    updateProductTransfer,
    exportProductTransfers,
    uploadProductTransfers,
    mailProductTransfers,
    uploadFileToProductTransfer,
    resubmitB2,
};
